// make all value to lower case only
export default {
  add: "add",
  update: "update",
  remove: "remove",
  branch: "branch | branches",
  book: "book",
  bookXpPackage: "book",
  bookNow: "book now",
  payNow: "pay now",
  bookAgain: "book again",
  orderAgain: "order again",
  order: "order",
  addOrder: "order",
  orderNow: "order now",
  placeOrder: "place order",
  selectOrderTime: "select order time",
  selectDeliveryTime: "select delivery / pickup date & time",
  selectDineInTime: "select booking date & time",
  dineIn: "dine in",
  selfPickUp: "self pick up",
  delivery: "delivery",
  deliveryNow: "Delivery now <span class='text-red-dark'>{minute} mins</span>",
  deliveryTime: "delivery time",
  estimated: "estimated",
  readyToPickupAt:
    "Ready to pick up estimated in next <span class='text-red-dark'>{minute} mins</span>",
  deliveryEstimated: "Est. Delivery Time",
  package: "package",
  date: "date",
  time: "time",
  payment: "payment",
  pax: "pax",
  people: "person | people",
  termAndCondition: "terms & conditions",
  confirm: "confirm",
  confirmation: "confirmation",
  change: "change",
  name: "name",
  email: "email",
  phone: "phone",
  new: "new",
  changePassword: "change password",
  password: "password",
  newPassword: "new password",
  confirmNewPassword: "confirm new password",
  confirmAndPay: "Confirm & Pay",
  currentPassword: "current password",
  restaurant: "restaurant | restaurants",
  dateAndTime: "date & time",
  numberOfPeople: "number of people",
  noOfPeople: "no of people",
  guest: "guest",
  method: "method",
  at: "at",
  packageType: "package type",
  deliveryAddress: "delivery address",
  note: "note",
  noteToDriver: "note to driver",
  specialRequest: "special request",
  customers: "customers",
  bloggers: "bloggers",
  seeMoreReview: "see more review",
  reviews: "{count} Reviews",
  loading: "loading",
  processYourBooking: "processing your booking",
  processingYourOrder: "processing your order",
  numberOfAdult: "{adult} Adult | {adult} Adults",
  numberOfKids: "{kids} Kid | {kids} Kids",
  adult: "adult | adults",
  kids: "kid | kids",
  cancel: "cancel",
  deliveryFee: "delivery fee",
  usingCustomDeliveryFee: "This package using special delivery fee",
  free: "free",
  freeDelivery: "free delivery",
  totalPrepaidAmount: "total prepaid amount",
  totalPrice: "total price",
  orderId: "order id",
  bookingId: "Booking ID",
  collect: "collect",
  redeem: "redeem",
  redeemPoint: "redeem points",
  course: "course | courses",
  redeemMessage: "this package can use voucher code",
  collectMessage: "this package can collect to hungry points",
  select: "select",
  selectPortions: "select {count} portion | select {count} portions",
  selected: "selected",
  location: "location",
  cuisines: "cuisines",
  price: "price",
  promotion: "promotion",
  packageDescription: "packages description",
  youAreOrdering: "you are ordering",
  youAreBooking: "you are booking",
  packages: "packages",
  readMore: "read more",
  more: "more",
  showLess: "show less",
  showMore: "show more",
  pleaseWait: "please wait",
  selectPackage: "select package",
  addonPackageAlert:
    "this package is add-on package, you need to choose the main package first",
  photosCount: "{count} Photos",
  addToFavourite: "add to favourite",
  favorites: "favorites",
  noFavourites: "no favorites to show.",
  noBookingHistory: "no booking history to show.",
  startSearching:
    "start searching for restaurants and discover our incredible deals!",
  lastReservationCTA: "Trending! Last booking was made",
  newRestaurantCTA: "New! Book & review to earn {point} hungry vouchers!",
  totalRestaurantBookingCTA: "Popular! Booked by {count} people",
  pleaseSelectAdult: "Please select number of people first",
  pleaseSelectPackage: "Please select package first",
  pleaseSelectPackageMenu: "Please select package's menu first",
  pleaseSelectDate: "Please select date first",
  pleaseSelectDateTime: "Please select date/time first",
  pleaseEnterPhone: "Please enter your phone no.",
  pleaseEnterEmail: "Please enter your email address",
  noLimit: "no limit",
  search: "search",
  searchRestaurantPlaceHolder: "Search Restaurant, Cuisine, Location",
  topRestaurants: "Top Restaurants",
  newRestaurants: "New Restaurants",
  nearMeRestaurant: "Restaurants Near Me",
  trending: "Trending",
  popularBrand: "Popular Brand",
  bestSelling: "Best Selling",
  topCategories: "Top Categories",
  popularStaycation: "Popular Staycation",
  perfectForDates: "Perfect for Dates",
  birthdayAndCelebrations: "Birthday and Celebrations",
  greatForGroups: "Great for Groups",
  allRestaurant: "All Restaurants",
  featuredRestaurants: "Featured Restaurants",
  customersReviews: "Customer Reviews",
  bloggersReviews: "Blogger Reviews",
  blog: "blog",
  promotions: "promotions",
  startingPrice: "Starting price",
  highestPrice: "Highest price",
  priceRange: "Price range",
  register: "register",
  promotionCode: "Referral/Promotion Code (Optional)",
  submit: "submit",
  signIn: "sign in",
  validUntil: "Valid until",
  rating: "{count} Review | {count} Reviews",
  viewOtherBranch: "View other branches",
  voucherNeedPrePayment:
    "Enter your credit card / debit card details below to use this voucher",
  viewAllRestaurants: "View all {count} restaurants",
  viewAllBlogger: "view all blogger reviews",
  filter: "filter",
  applyFilter: "apply filter",
  clear: "clear",
  hereAreYourSearchResult: "Here are your search result",
  noResultFound: "No result found",
  weCantFindResult: "We can’t find any item matching your search.",
  mapView: "map view",
  resetFilter: "reset filter",
  viewDetai: "view detail",
  detail: "detail",
  pleaseCheckYourNumber:
    "Please check your phone number, make sure its correct",
  bookItBeforeGone: "book it before gone",
  insufficientPoints: "insufficient points",
  yourHungryPoint: "your hungry point",
  viewMyPointVoucher: "view my hungry points and vouchers",
  colectPointToRedeem: "collect {point} points to redeem",
  confirmRedeem:
    "Do you want to convert <span class='font-black text-red-dark'> {point}</span> points into a voucher ?",
  youDontHaveVoucher: "You don't have any vouchers",
  expiryDate: "expiry date",
  voucherCode: "voucher code",
  useNow: "use now",
  occasion: "occasion",
  today: "today",
  tomorrow: "tomorrow",
  spcificDate: "specific date",
  specificTime: "specific time",
  pickDate: "pick a date",
  selectTime: "select time",
  upcomingReservation: "Upcoming Reservations",
  noUpcomingReservations: "No Upcoming Reservations",
  noReservationsMade: "No Reservation Has Been Made",
  findARestaurant: "Find a Restaurant",
  bookingHistory: "booking history",
  memberPriviliges: "member privileges",
  editProfile: "edit profile",
  saveChanges: "save changes",
  referralCode: "referral code",
  myHungryPoint: "my hungry points",
  myVoucher: "my vouchers",
  redeemVouchers: "redeem vouchers",
  getVoucher: "get voucher",
  confirmVoucherRedemption: "confirm voucher redemption",
  incompleteReservation: "incomplete reservation",
  firebaseEmailAlreadyRegisterd:
    "Your email already registered. Please try Google Login.",
  preBooking: "Pre-booking for <br> {date} onwards",
  giftVoucherCombined:
    'This "gift voucher" only can be combined with another gift voucher',
  refresh: "refresh",
  reservation: "reservation | reservations",
  pointHistory: "point history",
  leadingZeroPhone:
    "Please use mobile phone number, with format 08xxxxxxxx or 06xxxxxxx",
  minute: "minute",
  edit: "edit",
  points: "points",
  modifyBooking: "modify",
  seeAll: "see all",
  earnHungryPointForEvery: "Earn Hungry Point for every friend you refer",
  seeAllPreviousBooking: "See all previous bookings",
  payAtRestaurantFreeCancelation: "Pay at Restaurant - Free Cancelation",
  payAtHotelFreeCancelation: "Pay at Hotel - Free Cancellation",
  readTermAndCondition: "Read Terms & Conditions",
  sectionLimit: "This section already reach limit",
  packageSelectLimit:
    "The {packageName} package has a limit of {quantity} portion / customer / day",
  wrongCorporateEventRestaurantAlert:
    "It feels like you open the wrong corporate restaurant. Please try again",
  portion: "{order} portion",
  notAvailable: "not available",
  freeDeliveryJustForYou: "Free Delivery Just For You",
  cannotCombinePackage: "This package can't be combined with other packages",
  highLoadWarning:
    "You will get a confirmation sms / email shortly but due to high load there may be some delay, please don’t make duplicate booking.",
  packageNotAvailable: "This package not available for ",
  selectPayment: "Select payment method",
  chargedSummary: "Charged Summary",
  chargeSummary: "Charge Summary",
  totalChargeAmount: "Total Charge Amount",
  bookingAgreement:
    "By completing this order, I agree to all <a target='_blank' class='pb-1 font-bold underline text-red-dark' href={link}>Terms & Conditions.</a>",
  next: "next",
  copied: "copied",
  copy: "copy",
  share: "share",
  buy: "buy",
  pleaseInsertSelectAmount: "Please enter amount first",
  copiedVouchers: "Copied ({count} Vouchers)",
  voucher: "Voucher",
  giftCard: "Gift card",
  buyGiftCard: "buy gift card",
  packageExpired: "Package Expired",
  voucherRequestTax:
    "*In case of requesting an E-Receipt, please send details to E-MAIL : <b>finance@hungryhub.com</b> by inform the Voucher ID and customer details.",
  maintainPrivilegeWarning:
    "Order or book before {date} to keep your level and Hungry Points",
  total: "total",
  unlock: "unlock",
  currentLevel: "current level",
  subscribeNewsLetter:
    "I want to receive {amount} voucher, offers, feature updates, and promotional messages from Hungry Hub",
};
