// make all value to lower case only
export default {
  add: "เพิ่ม",
  update: "อัพเดท",
  remove: "ลบ",
  branch: "สาขา",
  book: "จองโต๊ะ",
  bookXpPackage: "จอง",
  bookNow: "จองตอนนี้",
  payNow: "จ่ายตอนนี้",
  bookAgain: "จองอีกครั้ง",
  orderAgain: "สั่งซื้ออีกครั้ง",
  order: "สั่งกลับบ้าน",
  addOrder: "สั่งอาหาร",
  orderNow: "สั่งอาหาร",
  placeOrder: "สั่งซื้อแล้ว",
  selectOrderTime: "เลือกเวลาที่ต้องการสั่ง",
  selectDeliveryTime: "เลือกวันและเวลารับ",
  selectDineInTime: "โปรดเลือกวันและเวลา",
  dineIn: "ทานที่ร้าน",
  selfPickUp: "รับด้วยตนเอง",
  delivery: "เดลิเวอรี่",
  deliveryNow: "จัดส่งทันที <span class='text-red-dark'>{minute} นาที</span>",
  deliveryTime: "จัดส่งภายใน",
  estimated: "ดยประมาณ",
  readyToPickupAt:
    "พร้อมให้มารับในอีก <span class=' text-red-dark'>{minute} นาทีโดยประมาณ</span>",
  deliveryEstimated: "เวลาจัดส่งโดยประมาณ",
  name: "ชื่อ",
  changePassword: "เปลี่ยนรหัสผ่าน",
  password: "รหัสผ่าน",
  newPassword: "รหัสผ่านใหม่",
  confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
  confirmAndPay: "ยืนยันและชำระเงิน",
  currentPassword: "รหัสผ่านปัจุบัน",
  email: "อีเมลล์",
  phone: "เบอร์โทร",
  new: "ใหม่",
  time: "เวลา",
  restaurant: "ร้านอาหาร",
  dateAndTime: "เวลาและวันที่",
  numberOfPeople: "จำนวนคน",
  noOfPeople: "จำนวนคน",
  guest: "แขกคนที่",
  method: "วิธีการ",
  at: "เวลา",
  packageType: "ประเภทแพ็คเก็จ",
  deliveryAddress: "ที่อยู่ผู้สั่งซื้อ",
  note: "รายละเอียด",
  noteToDriver: "แจ้งรายละเอียดสำหรับคนขับ",
  package: "แพคเก็จ",
  date: "วันที่",
  payment: "ยอดชำระ",
  people: "ท่าน",
  pax: "จำนวนคน",
  termAndCondition: "ข้อกำหนดและเงื่อนไข",
  confirm: "ยืนยัน",
  confirmation: "การยืนยัน",
  change: "เปลี่ยน",
  specialRequest: "คำขอพิเศษ",
  customers: "ลูกค้า",
  bloggers: "บล็อกเกอร์",
  seeMoreReview: "ดูรีวิวเพิ่มเติม",
  reviews: "{count} รีวิว",
  loading: "โปรดรอ",
  processYourBooking: "กำลังดำเนินการ",
  processingYourOrder: "ระบบกำลังดำเนินการ",
  adult: "ผู้ใหญ่",
  kids: "เด็ก",
  numberOfAdult: "ผู้ใหญ่ {adult} คน",
  numberOfKids: "เด็ก {kids} คน",
  cancel: "ยกเลิก",
  deliveryFee: "ค่าจัดส่ง",
  usingCustomDeliveryFee: "แพ็คเกจนี้มีค่าจัดส่งตามระยะทาง",
  free: "ฟรี",
  freeDelivery: "ฟรีค่าจัดส่ง",
  totalPrepaidAmount: "ยอดที่ต้องชำระจำนวนทั้งสิ้น",
  totalPrice: "ราคารวม",
  orderId: "รหัสการจอง",
  bookingId: "รหัสการจอง",
  collect: "สะสมคะแนน",
  redeem: "แลกคะแนน",
  redeemPoint: "แลกคะแนน",
  course: "คอร์ส",
  collectMessage: "แพ็กเกจนี้ร่วมรายการสะสม Hungry Points",
  redeemMessage: "แพ็กเกจนี้สามารถใช้ Voucher Code ได้",
  select: "เลือก",
  selectPortions: "เลือกได้ {count} รายการ",
  selected: "เลือก",
  location: "พื้นที่",
  cuisines: "อาหาร",
  price: "ราคา",
  promotion: "โปรโมชั่น",
  packageDescription: "รายละเอียดแพ็คเกจ",
  youAreOrdering: "ออเดอร์ของคุณ",
  youAreBooking: "การจองของคุณ",
  packages: "แพ็กเกจ",
  readMore: "อ่านต่อ",
  more: "มากกว่า",
  showLess: "ซ่อน",
  showMore: "แสดงมากขึ้น",
  pleaseWait: "โปรดรอ",
  selectPackage: "เลือกแพ็กเกจ",
  addonPackageAlert: "แพ็กเกจนี้เป็นแพ็กเกจเสริมต้องเลือกแพ็กเกจหลักก่อน",
  photosCount: "{count} รูปภาพ",
  addToFavourite: "เพิ่มในรายการโปรด",
  favorites: "รายการโปรด",
  noFavourites: "ไม่มีรายการโปรด",
  noBookingHistory: "ไม่มีประวัติการจอง",
  startSearching: "เริ่มค้นหาร้านอาหาร และค้นพบข้อเสนอสุดพิเศษของเรา",
  lastReservationCTA: "ร้านฮิต! จองครั้งสุดท้ายเมื่อ",
  newRestaurantCTA: "ใหม่! จองและรีวิวเพื่อรับเวาเชอร์ {point} บาท!",
  totalRestaurantBookingCTA: "มาแรง! {count} คนจองร้านนี้แล้ว",
  pleaseSelectAdult: "กรุณาเลือกจำนวนคน",
  pleaseSelectPackage: "กรุณาเลือกแพ็คเกจที่ต้องการ",
  pleaseSelectPackageMenu: "กรุณาเลือกเมนูที่ต้องการ",
  pleaseSelectDate: "กรุณาเลือกวันที่ต้องการ",
  pleaseSelectDateTime: "กรุณาเลือกวันและเวลาที่ต้องกา",
  pleaseEnterPhone: "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  pleaseEnterEmail: "กรุณากรอกอีเมลของคุณ",
  noLimit: "ไม่จำกัดจำนวน",
  search: "ค้นหา",
  searchRestaurantPlaceHolder: "ชื่อร้านอาหาร, ประเภท, สถานที่",
  topRestaurants: "ร้านอาหารยอดนิยม",
  newRestaurants: "ร้านอาหารใหม่",
  nearMeRestaurant: "ร้านอาหารใกล้ฉัน",
  trending: "กำลังมาแรง",
  popularBrand: "แบรนด์ยอดนิยม",
  bestSelling: "ขายดีที่สุด",
  topCategories: "หมวดหมู่ยอดนิยม",
  popularStaycation: "สเตเคชั่น",
  perfectForDates: "เหมาะสำหรับวันที่",
  birthdayAndCelebrations: "วันเกิดและการเฉลิมฉลอง",
  greatForGroups: "เหมาะสำหรับกลุ่ม",
  allRestaurant: "ร้านอาหารทั้งหมด",
  featuredRestaurants: "ร้านอาหารแนะนำ",
  customersReviews: "รีวิวจากลูกค้า",
  bloggersReviews: "รีวิวจากบล็อคเกอร์",
  blog: "บล็อก",
  promotions: "โปรโมชั่น",
  startingPrice: "ราคาเริ่มต้น",
  highestPrice: "ราคาสูงสุด",
  priceRange: "ช่วงราคา",
  register: "สมัครสมาชิก",
  promotionCode: "โค้ดแนะนำ (ไม่จำเป็นต้องกรอก)",
  submit: "เสร็จ",
  signIn: "เข้าสู่ระบบ",
  validUntil: "โปรโมชั่นถึงวันที่",
  rating: "{count} รีวิว",
  viewOtherBranch: "ดูสาขาอื่น",
  voucherNeedPrePayment:
    "กรอกข้อมูลบัตรเครดิต / บัตรเดบิตของคุณตามรายละเอียดด้านล่างเพื่อใช้ voucher นี้",
  viewAllRestaurants: "ดูร้านอาหาร {count} ร้านด้านล่าง",
  viewAllBlogger: "ดูรีวิวจากบล็อกเกอร์ทั้งหมด",
  filter: "กรอง",
  applyFilter: "ใช้ตัวกรอง",
  clear: "ล้าง",
  hereAreYourSearchResult: "ผลการค้นหาของคุณ",
  noResultFound: "ไม่พบผลลัพธ์",
  weCantFindResult: "เราไม่พบรายการที่ตรงกับการค้นหาของคุณ",
  mapView: "แผนที่",
  resetFilter: "รีเซ็ตตัวกรอง",
  viewDetai: "ดูรายละเอียด",
  detail: "รายละเอียด",
  pleaseCheckYourNumber: "กรุณาตรวจสอบหมายเลขโทรศัพท์ของคุณให้ถูกต้อง",
  bookItBeforeGone: "รีบจองก่อนหมด",
  insufficientPoints: "คะแนนไม่เพียงพอ",
  yourHungryPoint: "คะแนนของคุณ",
  viewMyPointVoucher: "ดูคะแนนของฉัน และวอชเชอร์",
  colectPointToRedeem: "สะสม {point} คะแนนเพื่อแลกวอชเชอร์",
  confirmRedeem:
    "คุณต้องการเปลี่ยน  <span class='font-black text-red-dark'> {point}</span> คะแนน เป็นวอชเชอร์หรือไม่",
  youDontHaveVoucher: "คุณไม่มีบัตรกำนัล",
  expiryDate: "วันหมดอายุ",
  voucherCode: "รหัสวอชเชอร์",
  useNow: "ใช้ตอนนี้",
  occasion: "โอกาสพิเศษ",
  today: "วันนี้",
  tomorrow: "พรุ่งนี้",
  spcificDate: "วันที่ที่ระบุ",
  specificTime: "เวลาที่ต้องการ",
  pickDate: "วันที่ต้องการ",
  selectTime: "เลือกเวลา",
  upcomingReservation: "การจองที่กำลังจะเกิดขึ้น",
  noUpcomingReservations: "ไม่มีการจองที่กำลังจะมาถึง",
  noReservationsMade: "ยังไม่มีการจอง",
  findARestaurant: "ค้นหาร้านอาหาร",
  bookingHistory: "ประวัติการจอง",
  memberPriviliges: "สิทธิพิเศษสำหรับสมาชิก",
  editProfile: "แก้ไขโปรไฟล์",
  saveChanges: "บันทึกการเปลี่ยนแปลง",
  referralCode: "โค้ดแนะนำเพื่อน",
  myHungryPoint: "Hungry Points ของฉัน",
  myVoucher: "วอชเชอร์ของฉัน",
  redeemVouchers: "แลกวอชเชอร์",
  getVoucher: "ได้รับวอชเชอร์",
  confirmVoucherRedemption: "ยืนยันการแลกวอชเชอร์",
  incompleteReservation: "incomplete reservation",
  firebaseEmailAlreadyRegisterd:
    "อีเมลของคุณลงทะเบียนแล้ว โปรดลองเข้าสู่ระบบด้วย Google",
  preBooking: "จองล่วงหน้าสำหรับ <br> {date} เป็นต้นไป",
  giftVoucherCombined: "กิ๊ฟวอชเชอร์นี้สามารถใช้ร่วมกับกิ๊ฟวอชเชอร์อื่น",
  refresh: "refresh",
  reservation: "การจอง",
  pointHistory: "ประวัติคะแนน",
  leadingZeroPhone:
    "กรุณาใช้หมายเลขโทรศัพท์มือถือรูปแบบ 08xxxxxxxx หรือ 06xxxxxxx",
  minute: "นาที",
  edit: "แก้ไข",
  points: "คะแนน",
  modifyBooking: "แก้ไขการจอง",
  seeAll: "ดูทั้งหมด",
  earnHungryPointForEvery: "ได้รับคะแนน Hungry Point ทุกการแนะนำเพื่อน",
  seeAllPreviousBooking: "ดูการจองก่อนหน้าทั้งหมด",
  payAtRestaurantFreeCancelation: "ชำระเงินที่ร้านอาหาร - ยกเลิกฟรี",
  payAtHotelFreeCancelation: "ชำระเงินที่โรงแรม - ยกเลิกฟรี",
  readTermAndCondition: "อ่านข้อกำหนดและเงื่อนไข",
  sectionLimit: "คุณได้เลือกรายการอาหารครบแล้วสำหรับหมวดนี้",
  packageSelectLimit:
    "แพ็ก {packageName} มีจำนวนจำกัดที่ {quantity} เซต / ลูกค้า / วัน",
  wrongCorporateEventRestaurantAlert:
    "ร้านอาหารดังกล่าว ไม่ใช่ร้านอาหารที่บริษัทได้ทำการเลือกไว้ โปรดลองใหม่อีกครั้ง",
  portion: "จานที่ {order}",
  notAvailable: "เมนูหมดชั่วคราว",
  freeDeliveryJustForYou: "ส่งฟรี เฉพาะคุณเท่านั้น!",
  cannotCombinePackage: "แพ็กเกจนี้ไม่สามารถใช้ร่วมกับแพ็กเกจอื่นได้",
  highLoadWarning:
    "คุณจะได้รับ SMS ยืนยัน / อีเมลในไม่ช้า แต่เนื่องจากโหลดสูง อาจมีความล่าช้า โปรดอย่าทำการจองซ้ำ",
  packageNotAvailable: "แพ็คเกจนี้ในรอบที่จอง เต็มแล้วครับ",
  selectPayment: "ช่องทางการชำระเงิน",
  chargeSummary: "สรุปยอดเงิน",
  chargedSummary: "สรุปยอดเงิน",
  totalChargeAmount: "ราคาสุทธิ",
  bookingAgreement:
    "<a target='_blank' class='pb-1 font-bold underline text-red-dark' href={link}>ฉันได้อ่านและยอมรับ</a>ในเงื่อนไขการจอง",
  next: "ถัดไป",
  copied: "คัดลอกแล้ว",
  copy: "คัดลอก",
  share: "แชร์",
  buy: "สั่งซื้อ",
  pleaseInsertSelectAmount: "ระบุยอดเงินที่ต้องการสั่งซื้อ",
  copiedVouchers: "คัดลอกแล้ว ({count} Vouchers)",
  voucher: "ส่วนลด",
  giftCard: "ส่วนลด",
  buyGiftCard: "สั่งซื้อ",
  packageExpired: "แพ็กเกจหมดอายุวันที่",
  voucherRequestTax:
    "*กรณีต้องการขอ ใบเสร็จรับเงิน สามารถส่งรายละเอียดมาได้ที่ E-MAIL : <b>finance@hungryhub.com</b> พร้อมแจ้ง Voucher ID ที่ต้องการขอใบเสร็จรับเงิน",
  maintainPrivilegeWarning:
    "โปรดทำการจองภายในวันที่ {date} เพื่อรักษาสิทธิพิเศษของคุณไว้!",
  total: "จองไปแล้ว",
  unlock: "ปลดล็อกระดับ",
  currentLevel: "ระดับของคุณ",
  subscribeNewsLetter:
    "ฉันต้องการรับเวาเชอร์มูลค่า {amount}, ข้อเสนอรวมถึงโปรโมชั่นต่าง ๆ และการอัปเดตฟีเจอร์ ใหม่จากทาง Hungry Hub",
};
